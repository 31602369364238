















































import { defineComponent, reactive, watch } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";
import { v4 as uuid } from "uuid";
import useMisc from "@/use/misc";

export default defineComponent({
  setup(_, { root }) {
    const axiosInstance = root.$store.getters[
      "api/getInstance"
    ] as AxiosInstance;
    const { goBack } = useMisc({ root });

    const state = reactive({
      loading: false,
      defaultSort: "createdAt",
      headers: [
        { value: "type", text: root.$tc("layout.misc.type"), sortable: false },
        {
          value: "participant",
          text: root.$tc("participant.status.participant"),
          sortable: false,
        },
        {
          value: "event",
          text: root.$tc("layout.misc.event"),
          sortable: false,
        },
        { value: "createdAt", text: root.$tc("alert.createdAt.title") },
        {
          value: "updateAt",
          text: root.$tc("alert.dateOfRead.title"),
          sortable: false,
        },
        {
          value: "readBy",
          text: root.$tc("alert.readBy.title"),
          sortable: false,
        },
      ],
      items: [],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["createdAt"],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      total: 0,
      error: false as unknown,
      dialog: false,
    });

    const model = reactive({
      status: [] as string[],
    });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("panel.event.alerts.error404")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const fetch = () => {
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get(`alert/event/${root.$route.params.id}`, {
          params: { page, itemsPerPage, sortBy, sortDesc },
        })
        .then(({ data }) => {
          state.items = data.alerts;
          state.total = data.total;
        })
        .catch(() => {
          state.items = [];
          state.total = 0;
        })
        .finally(() => (state.loading = false));
    };

    watch(() => state.options, fetch, { deep: true });

    const onRowClick = (event: MouseEvent, data: any) => {
      goBack("panel.alert.view", { alid: data.item.id });
    };

    const markAllAsRead = () => {
      axiosInstance
        .put(`alert/event/${root.$route.params.id}`)
        .then(() => {
          state.error = false;

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "success",
            message: root.$tc("panel.event.alerts.successMarkAsRead"),
          });
          state.dialog = false;
          fetch();
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    return {
      state,
      model,
      onRowClick,
      markAllAsRead,
    };
  },
});
